import { MenuItem } from "@mui/material";
import { T } from "../../../../util/t";
import { CloudUpload } from "@mui/icons-material";
import { SpreadsheetImporter } from "../../../../importer/SpreadsheetImporter";
import { getEventImportFields } from "../../../../importer/fields/eventImportFields";
import { useQuery } from "@apollo/client";
import { GET_ORGANISATION_WITH_FEDERATION_PROPERTIES } from "../../../../../graphql/organisation";
import { useState } from "react";
import { useOrganisation } from "../../../../providers/OrganisationProvider";
import { useEventObject } from "../EventProvider";
import {
    eventDivisionsAndEntriesFromData,
    stripName
} from "../../../../layout/event/dashboard/eventDivisionsAndEntriesFromData";
import { ConfirmationDialog } from "../../../../layout/dialogs/ConfirmationDialog";
import { useTranslate } from "../../../../providers/LocalizationProvider";
import { DetailsDialog } from "./DetailsDialog";

export const useRowHook = ( eventDivisions ) => {
    const nameMessage = useTranslate("Athlete names are required");
    const eventDivisionMessage = useTranslate("Divisions do not exist in the event");

    return (data, addError) => {
        if (!data.name && !data.firstName && !data.lastName) {
            addError("name", { nameMessage, level: "error" });
        }

        const matchedDivision = eventDivisions.find(eventDivision => eventDivision.division.name.trim().toLowerCase() === data.division.trim().toLowerCase());
        if (!matchedDivision) {
            addError("division", { eventDivisionMessage, level: "error" });
        }

        return { ...data,
            name: stripName(data.name) || [stripName(data.firstName), stripName(data.lastName)].filter(w => w).join(" "),
            eventDivisionId: matchedDivision?.id
        };
    };
};

export const ImportButton = ({
    title,
    openConfirmationDialog,
    confirmationDialogOpen,
    confirmationTitle,
    confirmationButtonTitle,
    confirmationContent,
    onClose,
    withResults = true,
    badImportContent,
    onImport,
}) => {
    const [isOpen, setIsOpen] = useState(false);
    const { id: organisationId } = useOrganisation();
    const event = useEventObject();
    const rowHook = useRowHook(event?.eventDivisions);
    const [eventDivisions, setEventDivisions] = useState([]);
    const [invalidEventDivisions, setInvalidEventDivisions] = useState([]);
    const [entries, setEntries] = useState([]);

    const { data } = useQuery(GET_ORGANISATION_WITH_FEDERATION_PROPERTIES, { variables: { id: organisationId } }),
        organisation = data?.organisation;

    const onSetData = data => {
        const { eventDivisions, entries, invalidEventDivisions } = eventDivisionsAndEntriesFromData(data, organisation, withResults);
        setEventDivisions(eventDivisions);
        setEntries(entries);
        setInvalidEventDivisions(invalidEventDivisions);
        openConfirmationDialog();
    };

    if (!organisation) return null;

    return (
        <>
            <MenuItem onClick={() => setIsOpen(true)}>
                <T>{title}</T>
                &nbsp;&nbsp;
                <CloudUpload fontSize="small"/>
            </MenuItem>

            <SpreadsheetImporter
                isOpen={isOpen}
                onClose={() => setIsOpen(false)}
                onSubmit={onSetData}
                fields={getEventImportFields(organisation, withResults)}
                rowHook={rowHook}
                isNavigationEnabled
            />

            {eventDivisions?.length ?
                <ConfirmationDialog open={confirmationDialogOpen}
                                    onClose={onClose}
                                    action={onImport(event.id, entries)}
                                    title={confirmationTitle}
                                    content={<T divisions={eventDivisions?.length} invalidEventDivisions={invalidEventDivisions?.length}>{confirmationContent}</T>}
                                    buttonTitle={confirmationButtonTitle}
                                    buttonProps={{ disabled: false }} /> :
                <DetailsDialog title={confirmationTitle}
                               content={<T>{badImportContent}</T>}
                               open={confirmationDialogOpen}
                               onClose={onClose} />}
        </>
    );
};
