import { FormField, selectInput } from "../../forms";
import { T } from "../../util/t";
import { Field } from "redux-form/immutable";
import Immutable from "immutable";
import { useQuery } from "@apollo/client";
import { GET_ORGANISATION_WITH_POINT_ALLOCATIONS } from "../../../graphql/organisation";
import { useTranslate } from "../../providers/LocalizationProvider";
import { HelpTooltip } from "../../elements/helpTooltip";

const parseParentDivision = parent => value => Immutable.fromJS({
    id: parent.get("id"),
    season_id: parent.get("series_id"),
    division_id: value,
    _destroy: !value
});

const formatParentDivision = value => value && value.get("division_id");

export const ParentSeriesDivisionMapping = ({ index, parentDivision, parentSeries, organisationId }) => {
    const { data } = useQuery(GET_ORGANISATION_WITH_POINT_ALLOCATIONS, { variables: { id: organisationId } }),
        pointAllocations = data?.organisation?.federationPointAllocations || [];

    const seriesPointAllocation = pointAllocations.find(({ id }) => `${id}` === `${parentSeries?.get("point_allocation_id")}`),
        liveHeatsPointAllocationDefault = useTranslate("Liveheats default"),
        seriesPointAllocationDefault = useTranslate("series default"),
        pointAllocationDefault = seriesPointAllocation ? `${seriesPointAllocation?.name} (${seriesPointAllocationDefault})` : liveHeatsPointAllocationDefault;

    const options = [
        { value: "", label: pointAllocationDefault },
        ...pointAllocations.map(({ id, name }) => ({ value: id, label: name }))
    ];

    // eslint-disable-next-line eqeqeq
    if (parentDivision?.get("point_allocation_id") && !options.find(({ value }) => value == parentDivision.get("point_allocation_id")))
        options.push({ value: parentDivision.get("point_allocation_id"), label: parentDivision.get("point_allocation_name") });

    return <FormField>
        <label>{parentDivision.get("series_name")} ({parentDivision.get("organisation_name")})</label>
        <br/>

        <T>Mapped division for rankings:</T>
        <HelpTooltip><T org={parentDivision.get("organisation_name")}
                        series={parentDivision.get("series_name")}>maps_to_org_division_help</T></HelpTooltip>
        <Field name={`division_to_seasons_attributes[${index}]`}
               component={selectInput}
               parse={parseParentDivision(parentDivision)}
               format={formatParentDivision}
               selectOptions={parentDivision.get("rankings_divisions")
                   .map(s => ({ label: s.get("name"), value: s.get("id") }))
                   .unshift({ label: useTranslate("No related division"), value: "" })
               }/>

        <T>Points allocation:</T>
        <Field name={`division_to_seasons_attributes[${index}].point_allocation_id`}
               component={selectInput}
               selectOptions={options} />
    </FormField>;
};
